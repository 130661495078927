import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api.service";
export class AssessmentService {
    constructor(http, api) {
        this.http = http;
        this.api = api;
        this.Url = `${environment.apiurl}`;
        this.assessComponentList = new BehaviorSubject([]);
        this.assessComponentTaskList = new BehaviorSubject([]);
        this.assessComponentTaskData = new BehaviorSubject([]);
        this.assessComponentGroupUsers = new BehaviorSubject([]);
        this.assessComponentTaskMetaData = new BehaviorSubject([]);
        this.s_selectedComponentId = new BehaviorSubject([]);
        this.s_selectedTaskId = new BehaviorSubject([]);
        this.s_selectedCollabrationId = new BehaviorSubject([]);
        this.bs_ProjectProgress = new BehaviorSubject([]);
        this.s_SaveComponent = new Subject();
        this._projectProgress = this.bs_ProjectProgress.asObservable();
        this._currentComponent = this.s_selectedComponentId.asObservable();
        this._currentTask = this.s_selectedTaskId.asObservable();
        this._currentCollabration = this.s_selectedCollabrationId.asObservable();
        this._taskList = this.assessComponentTaskList.asObservable();
        this._componentList = this.assessComponentList.asObservable();
    }
    getProjectComponentList(group_id) {
        this.http.post(this.Url + '/project/componentList', { group_id }).subscribe((res) => {
            if (res && res.status) {
                console.log('ComponentList');
                this.assessComponentList.next(res['data']);
            }
        });
    }
    getProjectComponentTask(group_id, component_id) {
        this.http.post(this.Url + '/' + 'jain/component/taskList', { group_id, component_id }).subscribe((res) => {
            if (res && res.status) {
                console.log('TaskList');
                this.assessComponentTaskList.next(res['data']);
            }
        });
    }
    setSelectedComponent(component_id) {
        this.s_selectedComponentId.next(component_id);
    }
    setSelectedTask(task_id) {
        this.s_selectedTaskId.next(task_id);
    }
    setSelectedColabration(component_id) {
        this.s_selectedCollabrationId.next(component_id);
    }
    getProjectProgress(group_id) {
        this.http.post(this.Url + '/project/projectProgress', { group_id }).subscribe((res) => {
            if (res && res.status) {
                // console.log('Project Progress');
                this.bs_ProjectProgress.next(res['data']);
            }
        });
    }
    saveProjectComponent(group_id, component_id) {
        this.http.post(this.Url + '/project/saveComponent', { group_id, component_id }).subscribe((res) => {
            if (res && res.status) {
                // console.log(res.message);
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.message
                });
            }
            this.s_SaveComponent.next(res.status);
        });
        return this.s_SaveComponent.asObservable();
    }
}
AssessmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AssessmentService_Factory() { return new AssessmentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Api)); }, token: AssessmentService, providedIn: "root" });
