import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class AuthService {
    constructor(http, route) {
        this.http = http;
        this.route = route;
        this.initialUserData = {
            fullname: '',
            email: '',
            user_type: '',
            profile_image: '',
            _id: '',
            first_name: '',
            last_name: '',
            city: '',
            state: '',
            city_id: '',
            state_id: '',
            is_phone_verify: '',
            phone: '',
            about: '',
            year: '',
            course: '',
            school: '',
            username: '',
            gender: '',
            cover_image: '',
            theme_image: '',
            is_friend: 0
        };
        this.currentUserSubject = new BehaviorSubject(this.initialUserData);
        this.currentUserDashboardSubject = new BehaviorSubject(this.initialUserData);
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentUserDashboard = this.currentUserDashboardSubject.asObservable();
        if (localStorage.getItem(environment.accessToken)) {
            this.isLoggedinSubject = new BehaviorSubject(true);
            this.getUserDetail().subscribe();
        }
        else {
            this.isLoggedinSubject = new BehaviorSubject(false);
        }
    }
    get currentUserValue() {
        return this.currentUserSubject.value;
    }
    // public get isLoggedin() {
    //   return this.isLoggedinSubject.value;
    // }
    login(email, password) {
        return this.http
            .post(`${environment.apiurl}/jain/studLogin`, { email, password })
            .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.status) {
                localStorage.setItem(environment.accessToken, user.data[0].token);
                this.isLoggedinSubject.next(true);
            }
            return user.status;
        }));
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(environment.accessToken);
        // document.location.href = 'https://app.careergraph.com';
        localStorage.removeItem('GroupCode');
        // this.currentUserSubject.next(null);
        // this.currentUserDashboardSubject.next(null);
        // this.isLoggedinSubject.next(false);
        this.route.navigateByUrl('/login');
    }
    getUserDetail() {
        return this.http
            .post(`${environment.apiurl}/jain/getDashboardData`, {})
            .pipe(map(userdata => {
            if (userdata && userdata.status) {
                this.currentUserSubject.next(userdata.data[0]);
                this.currentUserDashboardSubject.next(userdata.data[0]);
                return userdata.data;
            }
            else {
                this.logout();
            }
        }));
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: AuthService, providedIn: "root" });
