import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io";
import * as i2 from "@angular/common/http";
export class NotificationService {
    constructor(socket, http) {
        this.socket = socket;
        this.http = http;
        this.Url = `${environment.apiurl}`;
        this._bsnotificationList = new BehaviorSubject([]);
        this._notificationList = this._bsnotificationList.asObservable();
        this.notificationCount = this.socket.fromEvent('notificationCount');
    }
    joinRoom(id) {
        console.log('Group', id);
        this.socket.emit('joinRoom', id);
    }
    updateNotificationViewStatus() {
        this.http.post(this.Url + '/viewStatus', {}).subscribe((res) => {
            if (res && res.status) {
                console.info('notification');
                console.log(res['metadata']);
                res['data'].forEach(element => {
                    element.profile_image = res['metadata'][0]['profile_image_path'] + element.profile_image;
                });
                this._bsnotificationList.next(res['data']);
            }
        });
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.Socket), i0.ɵɵinject(i2.HttpClient)); }, token: NotificationService, providedIn: "root" });
