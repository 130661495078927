
import { AssessmentService } from './../../_cluster/services/assessment.service';
import { Component, OnInit, ViewChild, HostListener, Renderer2, ElementRef } from '@angular/core';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { User } from 'src/app/_cluster/_modal/user';
import { AuthService } from 'src/app/_cluster/services/auth.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StudentService } from 'src/app/_cluster/services/student.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-assessment-navigation',
  templateUrl: './assessment-navigation.component.html',
  styleUrls: ['./assessment-navigation.component.scss']
})
export class AssessmentNavigationComponent implements OnInit {
  @ViewChild('CollapseExpand', {
    static: true
  }) CollapseExpand: ElementRef;
  // @ViewChild('removetext', { static: false }) removetext: ElementRef;
  @ViewChild('togglePadding', {
    static: true
  }) togglePadding: ElementRef;
  // ** // Alert on Refresh and close
  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any) {
  //   // if (this.hasUnsavedData()) {
  //   console.log('returnva')
  //   $event.returnValue = true;
  //   // }
  // }
  projectComponentList = this.assessmentService._componentList;
  projectComponentTaskList = this.assessmentService._taskList;
  subscriptionComponentList: Subscription;
  subscriptionComponentTaskList: Subscription;
  userDetails: User;
  activeComponent = '';
  activeTask = '';
  activeColabration = '';
  taskData = [];
  listData: any;
  data: any;
  groupCode = '';
  projectMetaData = {
    progress: 0,
    company_logo: '',
    project_name: '',
    company_name: ''
  };
  toggleClick = false;
  toggleChangeClass = 'appear';
  toggleChangeClassMargin = '';
  toggleChangeBlockBG = 'block-bg';
  subjectName = '';
  type: string;
  message = '';
  supportForm: FormGroup;
  typeList = [
    { label: 'Report a bug', value: '2' },
    { label: 'Feature request', value: '3' },
    { label: 'Feedback', value: '1' },
    { label: 'Assistance Required', value: '4' }];
  constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private authService: AuthService, private studentService: StudentService, private formBuilder: FormBuilder,
    private renderer: Renderer2) {


  }

  ngOnInit() {
    this.supportForm = this.formBuilder.group({
      subject: ['', Validators.required],
      type: [null, Validators.required],
      message: ['', Validators.required]
    });
    window.onbeforeunload = function (evet) {
      // event.returnValue = true;
      // return "Do you really want to close?";
    };
    this.taskData = [];
    this.listData = [];
    this.assessmentService._currentComponent.subscribe(selectedComponent => {
      this.activeComponent = selectedComponent;
    });

    this.assessmentService._currentTask.subscribe(selectedTask => {
      this.activeTask = selectedTask;
      //console.log(selectedTask);
    });
    this.assessmentService._currentCollabration.subscribe(selectedCollaration => {
      this.activeColabration = selectedCollaration;
    });

    this.authService.currentUser.subscribe((user: User) => {
      this.userDetails = user;
    });

    this.groupCode = localStorage.getItem('GroupCode');
    this.assessmentService.getProjectComponentList(this.groupCode);
    this.assessmentService.getProjectProgress(this.groupCode);
    this.assessmentService._projectProgress.subscribe(projectdata => {
      if (!_.isUndefined(projectdata[0])) {
        // console.log(projectdata);
        this.projectMetaData = projectdata[0];
        // console.log(this.projectMetaData);

      }
    });

    this.subscriptionComponentList = this.projectComponentList.subscribe(res => {
      this.taskData = [];
      if (!_.isEmpty(res)) {
        this.listData = res;
      }
    });

    this.subscriptionComponentTaskList = this.projectComponentTaskList.subscribe(res => {
      // console.log('taskList')
      this.taskData = []
      this.taskData = [];
      if (!_.isEmpty(res)) {
        this.taskData = res;
      }
    });

  }
  gotoComponent(item, i) {
    this.activeComponent = '';
    this.assessmentService.setSelectedComponent('');
    //this.activeComponent = item.component_id;
    this.assessmentService.getProjectComponentTask(this.groupCode, item.component_id);
    this.assessmentService.setSelectedComponent(item.component_id);
    // this.collapseChange();
    this.assessmentService.setSelectedTask('');
    this.assessmentService.setSelectedColabration('');
    if (item.component_id) {
      this.router.navigateByUrl('/assessment/component/' + item.component_id);
    }

  }
  gotoCollabration(item) {
    // console.log(item, i);
    if (item.component_id) {
      this.assessmentService.setSelectedTask('');
      this.assessmentService.setSelectedColabration(item.component_id);
      // this.router.navigateByUrl('/assessment/collaborative/' + item.component_id);
      this.router.navigateByUrl('/assessment/presentation/' + item.component_id);
    }
  }
  gotoTask(item) {
    // console.log(item._id);

    this.assessmentService.setSelectedTask(item._id);
    this.assessmentService.setSelectedColabration('');
    this.router.navigateByUrl('/assessment/task/' + item._id);
  }
  componentLocked() {
    this.taskData = []
    Swal.fire({
      icon: 'info',
      title: 'Component is Locked',
      text: 'Complete the Above Component'
    });
    console.log('Component is locked');
  }
  collabrationLocked(item) {
    Swal.fire({
      title: 'Wait! Are you sure?',
      text: 'You won\'t be able to edit the task of this component!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Start Collabration'
    }).then((result) => {
      if (result.value) {
        this.assessmentService.saveProjectComponent(this.groupCode, item.component_id)
          .subscribe(status => {
            if (status) {
              this.assessmentService.getProjectComponentList(this.groupCode);
              this.gotoCollabration(item);
            }
          });
      }
    });
    console.log('collaboration is locked');
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    // if (this.subscriptionComponentTaskList) {
    //   this.subscriptionComponentTaskList.unsubscribe();
    // }

    // if (this.subscriptionComponentList) {
    //   this.subscriptionComponentList.unsubscribe();
    // }

  }

  // pdf downlaod and preview 

  generatePdf(action = 'open') {
    const documentDefinition = this.getDocumentDefinition();

    switch (action) {
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition).download();
        break;

      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }

  }

  getDocumentDefinition() {
    // localStorage.setItem('resume', JSON.stringify(this.resume));
    return {
      pageOrientation: 'landscape',
      pageSize: 'A5',
      watermark: {
        text: 'careergraph',
        color: '#c7c7c7',
        opacity: 0.3,
        bold: true,
        italics: false
      },
      content: [{
        text: 'Text on Portrait'
      },
      {
        text: 'Text on Landscape',
        pageBreak: 'before'
      },
      {
        text: 'Text on Landscape 2',
        pageBreak: 'after'
      },
      {
        text: 'Text on Portrait 2'
      },
      ]
    };
  }
  goTODashBoard() {
    Swal.fire({
      title: 'Hold on!',
      text: 'Are you sure you want to exit?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Exit'
    }).then((result) => {
      if (result.value) {
        this.subscriptionComponentTaskList.unsubscribe();
        this.subscriptionComponentList.unsubscribe();
        this.router.navigate(['/student/dashboard/user', this.userDetails.username]);
      }
    })
  }
  support() {
    this.supportForm.markAllAsTouched();
    if (this.supportForm.valid) {
      // console.log(this.supportForm.get('subject').value, this.supportForm.get('type').value, this.supportForm.get('message').value);
      this.studentService.supportfeedback(this.supportForm.get('subject').value, this.supportForm.get('type').value, this.supportForm.get('message').value);
      this.supportForm.reset();
    }
  }
  collapseChange() {
    this.toggleChangeClass === 'appear' ? this.toggleChangeClass = 'disappear' : this.toggleChangeClass = 'appear';
    this.toggleChangeClassMargin === '' ? this.toggleChangeClassMargin = 'ml--5' : this.toggleChangeClassMargin = '';
    this.toggleChangeBlockBG === '' ? this.toggleChangeBlockBG = 'block-bg' : this.toggleChangeBlockBG = '';

    // this.toggleChangeClass === 'appear' ? this.toggleChangeClass = 'disappear' : this.toggleChangeClass = 'appear';
    // this.toggleChangeClassMargin === '' ? this.toggleChangeClassMargin = 'ml--5' : this.toggleChangeClassMargin = '';
    // this.toggleClick = !this.toggleClick;
    // if (this.toggleClick) {
    //   this.toggleChangeClass = 'disappear';
    // } else {
    //   this.toggleChangeClass = 'appear';
    // }
  }
  // -- Custom Toggle Durgesh --
  toggleClass() {
    // console.log(this.expandBlock); togglePadding
    const withslideOn = this.CollapseExpand.nativeElement.classList.contains('sidebar-expanded');
    if (withslideOn) {
      this.renderer.removeClass(this.CollapseExpand.nativeElement, 'sidebar-expanded');
      this.renderer.addClass(this.CollapseExpand.nativeElement, 'sidebar-collapse');
      // this.renderer.removeClass(this.removetext.nativeElement, 'appear');
      // this.renderer.addClass(this.removetext.nativeElement, 'disappear');
      this.renderer.addClass(this.togglePadding.nativeElement, 'py-2');
      this.renderer.removeClass(this.togglePadding.nativeElement, 'p-3');
    } else {
      this.renderer.removeClass(this.CollapseExpand.nativeElement, 'sidebar-collapse');
      this.renderer.addClass(this.CollapseExpand.nativeElement, 'sidebar-expanded');
      // this.renderer.removeClass(this.removetext.nativeElement, 'disappear');
      // this.renderer.addClass(this.removetext.nativeElement, 'appear');
      this.renderer.removeClass(this.togglePadding.nativeElement, 'py-2');
      this.renderer.addClass(this.togglePadding.nativeElement, 'p-3');
    }
    this.collapseChange();

  }

}