import { AuthGuard } from 'src/app/_cluster/_auth/auth.guard';
import { Routes } from '@angular/router';
import { PreLoginNavigationComponent } from './layouts/pre-login-navigation/pre-login-navigation.component';
import { PostLoginNavigationComponent } from './layouts/post-login-navigation/post-login-navigation.component';
import { AssessmentNavigationComponent } from './layouts/assessment-navigation/assessment-navigation.component';
const ɵ0 = () => import("./auth/auth.module.ngfactory").then(n => n.AuthModuleNgFactory), ɵ1 = () => import("./pages/students/students.module.ngfactory").then(n => n.StudentsModuleNgFactory), ɵ2 = () => import("./pages/assessment/exports/exports.module.ngfactory").then(n => n.ExportsModuleNgFactory), ɵ3 = () => import("./pages/assessment/assessment.module.ngfactory").then(n => n.AssessmentModuleNgFactory), ɵ4 = () => import("./pages/user-profile/user-profile.module.ngfactory").then(n => n.UserProfileModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: '/student/dashboard/user',
        pathMatch: 'full'
    },
    {
        path: '',
        component: PreLoginNavigationComponent,
        loadChildren: ɵ0,
    },
    {
        path: 'student',
        component: PostLoginNavigationComponent,
        canActivate: [AuthGuard],
        loadChildren: ɵ1,
    },
    {
        path: 'export',
        // component: ,
        loadChildren: ɵ2,
    },
    {
        path: 'assessment',
        canActivate: [AuthGuard],
        component: AssessmentNavigationComponent,
        loadChildren: ɵ3,
    },
    {
        path: ':username',
        component: PreLoginNavigationComponent,
        loadChildren: ɵ4,
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
