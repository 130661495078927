import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-toastr";
import * as i3 from "@angular/router";
export class StudentService {
    constructor(http, ts, route) {
        this.http = http;
        this.ts = ts;
        this.route = route;
        this.Url = `${environment.apiurl}`;
        this.classmateList = new BehaviorSubject([]);
        this.friendsDashboardData = new BehaviorSubject([]);
    }
    getClassmateList(user_id) {
        this.http.post(this.Url + '/' + 'jain/classmatelist', { user_id }).subscribe((res) => {
            if (res && res.status) {
                // console.log('classmatelist', res);
                this.classmateList.next(res);
            }
        });
    }
    getFriendsDashboardData(username) {
        this.http.post(this.Url + '/' + 'user/userdetails', { username }).subscribe((res) => {
            if (res && res.status) {
                this.friendsDashboardData.next(res.data[0]);
            }
        });
    }
    shareMyProjectsList(username, page, limit, search_term, filter) {
        let jData = {
            username,
            page,
            limit,
            search_term,
            filter
        };
        return this.http.post(this.Url + '/' + 'jain/shareMyProjectsList', jData);
    }
    updateGroupUserRoles(group_id, to_user_id, role) {
        this.http.post(this.Url + '/' + 'jain/updateGroupUserRoles', { group_id, to_user_id, role }).subscribe((res) => {
            if (res && res.status) {
                this.ts.success(res.message);
            }
            else {
                this.ts.error(res.message);
            }
        });
    }
    supportfeedback(subject, type, message) {
        this.http.post(this.Url + '/' + 'project/supportfeedback', { subject, type, message }).subscribe((res) => {
            if (res && res.status) {
                this.ts.success(res.message);
            }
            else {
                this.ts.error(res.message);
            }
        });
    }
    guestsupportfeedback(name, email, subject, type, message) {
        this.http.post(this.Url + '/' + 'project/guestsupportfeedback', { name, email, subject, type, message }).subscribe((res) => {
            if (res && res.status) {
                this.ts.success(res.message);
            }
            else {
                this.ts.error(res.message);
            }
        });
    }
}
StudentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentService_Factory() { return new StudentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.Router)); }, token: StudentService, providedIn: "root" });
