<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light white fixed-top custom-navbar" [containerInside]="true">
  <mdb-navbar-brand>
    <a class="navbar-brand" (click)="goToProfile()" routerLink="/student/dashboard/user/{{userDetails.username}}">
      <img src="./assets/cg-logo/CareerGraph-Logo.png" style="width:240px;" alt="CareerGraph Logo" class="m-0 mr-4">
    </a>
  </mdb-navbar-brand>
  <links>
    <ul class="navbar-nav mr-auto"></ul>
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="text-center nav-item" style="padding: 0px 10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect (click)="goToProfile()"
          routerLink="/student/dashboard/user/{{userDetails.username}}" routerLinkActive="cg-active">
          <i class="fad fa-chalkboard fa-lg"></i>
          <br><small>Dashboard</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect routerLink="/student/allprojects"
          (click)="goToProfile()" routerLinkActive="cg-active">
          <i class="fad fa-pallet fa-lg"></i>
          <br><small>Project</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect (click)="comingsoon()">
          <i class="fad fa-users-crown fa-lg"></i>
          <br><small>Network Wall</small>
        </a>
      </li>
      <li class="nav-item avatar dropdown" style="padding: 8px;" dropdown (click)="viewNotification()">
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pl-1 pb-0 pt-3 text-center"
          mdbWavesEffect>
          <i class="fas fa-bell fa-lg"></i>
          <span class="badge2">{{notificationCount}}</span>
          <br><small>Notification</small>
          <i class="fas fa-sort-down mx-1" style="vertical-align: text-top;"></i>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu" style="padding: 0px; box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);">
          <div class="notification_header">
            YOU HAVE {{newNotification}} NEW NOTIFICATIONS!
          </div>
          <div class="notification_content" *ngIf="notificationList.length > 0"  >
            <!-- Normal Notification -->
            <div  *ngFor="let item of notificationList;let i=index">
            <div class="content_box"  *ngIf="i<3" >
              <div class="profile_pic"> 
                <img [src]="item.profile_image" alt="" class="img-fluid rounded-circle z-depth-0" >
              </div>
              <div class="content" *ngIf="item.notification_type==1" >
                <p> <span style="font-weight:500">{{item.sent_by}}</span> sent you {{item.notification_title}}</p>
                <span><i class="far fa-clock"></i> {{item.timeAgo}}</span>
                <div class="d-flex mt-3">
                  <button mdbBtn type="button" color="success" class="m-0" mdbWavesEffect style="width: 80px; padding: 5px 0px; border-radius: 3px; text-transform: capitalize; font-size: 0.75rem;" routerLink="/student/dashboard/subject/{{item.subject_id}}" >View</button>
                  <!-- <button mdbBtn type="button" color="danger" class="m-0 ml-2" mdbWavesEffect style="width: 80px; padding: 5px 0px; border-radius: 3px; text-transform: capitalize; font-size: 0.75rem;">Delete</button>  -->
                </div>
                
              </div>
            </div>
            </div>

            <!-- Friend Request Notification -->
            <!-- <div class="content_box">
              <div class="profile_pic">
                <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" alt="" class="img-fluid rounded-circle z-depth-0" >
              </div>
              <div class="content">
                <p><span style="font-weight:500">Lorem ipsum</span> sent you a friend request</p>
                <div class="d-flex mt-3">
                  <button mdbBtn type="button" color="success" class="m-0" mdbWavesEffect style="width: 80px; padding: 5px 0px; border-radius: 3px; text-transform: capitalize; font-size: 0.75rem;">Confirm</button>
                  <button mdbBtn type="button" color="danger" class="m-0 ml-2" mdbWavesEffect style="width: 80px; padding: 5px 0px; border-radius: 3px; text-transform: capitalize; font-size: 0.75rem;">Delete</button> 
                </div>
                <span><i class="far fa-clock"></i> 03:00pm</span>
              </div>
            </div> -->

            <!-- Friend Request Deleted Notification -->
            <!-- <div class="content_box">
              <div class="profile_pic">
                <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-5.jpg" alt="" class="img-fluid rounded-circle z-depth-0" >
              </div>
              <div class="content">
                <p><span style="font-weight:500">Lorem ipsum</span> sent you a friend request</p>
                <p style="color: #f98282; font: 0.60rem;" class="mt-2">Deleted</p>
              </div>
            </div> -->

            <!-- Working On Project Notification -->
            <!-- <div class="content_box" style="background-color: #fbfdff">
              <div class="profile_pic d-flex">
                <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-4.jpg" alt="" class="img-fluid rounded-circle z-depth-0" >
                <div class="proj">+3</div>
              </div>
              <div class="content">
                <p><span style="font-weight:500">Lorem ipsum +3</span> currently working on CareerGraph<p></p> 
                <span><i class="far fa-clock"></i> 03:00pm</span>
              </div>
            </div> -->

            <!-- Friend Request Accepted Notification -->
            <!-- <div class="content_box">
              <div class="profile_pic">
                <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-3.jpg" alt="" class="img-fluid rounded-circle z-depth-0" >
              </div>
              <div class="content">
                <p><span style="font-weight:500">Lorem ipsum</span> accepted your friend request</p>
                <span><i class="far fa-clock"></i> Just now</span>
              </div>
            </div> -->
          </div>
          <div class="notification_footer" routerLink="/student/notifications">
            View All Notifications
          </div>
        </div>
      </li>
      <li class="nav-item avatar dropdown" style="padding: 8px;" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pl-1 text-center"
          mdbWavesEffect>
          <img *ngIf="userDetails.profile_image" [src]="userDetails.profile_image" [name]="userDetails.fullname"
            class="img-fluid rounded-circle hoverable"
            style="width: 30px !important;height: 30px !important;margin-top: 10px;box-shadow: 0px 1px 12px -5px #000;">
          <img *ngIf="!userDetails.profile_image"
            [src]="'./assets/images/global/CareerGraph-Avatars/avatar-default.png'" [name]="userDetails.fullname"
            class="img-fluid rounded-circle hoverable"
            style="width: 30px !important;height: 30px !important;margin-top: 10px;box-shadow: 0px 1px 12px -5px #000;">
          <br><small>Me</small>
          <i class="fas fa-sort-down mx-1" style="vertical-align: text-top;"></i>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="profile-update">My account</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="support">Support</a>
          <div class="divider dropdown-divider"></div>
          <a class="dropdown-item waves-light" mdbWavesEffect (click)="logout()">Log out</a>
        </div>
      </li>
    </ul>
  </links>
</mdb-navbar>
<div class="container container-shadow post-content-block-adjustment ScrollingBlock">
  <router-outlet></router-outlet>
</div>