import { element } from 'protractor';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  Url = `${environment.apiurl}`;

_bsnotificationList : BehaviorSubject<any> = new BehaviorSubject([]);
_notificationList=this._bsnotificationList.asObservable();

notificationCount = this.socket.fromEvent<any>('notificationCount');
  constructor(
    private socket: Socket,
    private http: HttpClient,) { }

  joinRoom(id: any) {
    console.log('Group',id)
    this.socket.emit('joinRoom', id);
  }

  updateNotificationViewStatus() {
    this.http.post(this.Url + '/viewStatus',{}).subscribe((res: any) => {
      if (res && res.status) {
        console.info('notification');
        console.log(res['metadata']);
        res['data'].forEach(element => {
          element.profile_image=res['metadata'][0]['profile_image_path']+element.profile_image;

        });
        this._bsnotificationList.next(res['data']);
      } 
    });
  }
}
