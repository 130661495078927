import { NotificationService } from './../../_cluster/services/notification.service';
import { User } from './../../_cluster/_modal/user';
import { AuthService } from 'src/app/_cluster/services/auth.service';
import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-post-login-navigation',
  templateUrl: './post-login-navigation.component.html',
  styleUrls: ['./post-login-navigation.component.scss']
})
export class PostLoginNavigationComponent implements OnInit {
  userDetails: User;
  notificationCount=0
  notificationList=[]
  newNotification=0
  constructor(
    private authService        : AuthService,
    private router             : Router,
    private route              : ActivatedRoute,
    private notificationService:NotificationService) { }

  ngOnInit() {
    this.authService.currentUser.subscribe((user: User) => {
      this.userDetails = user;
      // console.log('user',user)
      this.notificationService.joinRoom({user_id:this.userDetails._id})
    });


    this.route.params.subscribe(param => {
      // console.log('activateRoute', param)
    })
    this.notificationService.notificationCount.subscribe(data=>{
      console.log(data);
      this.notificationCount=data
    })
  }
  logout() {
    this.authService.logout();
  }
  goToProfile() {
    this.authService.getUserDetail().subscribe();
    this.getMyData();
  }
  goToWall() {
    // this.getMyData();
    this.router.navigate(['/student/wall/user', this.userDetails.username]);
    this.authService.getUserDetail().subscribe();
  }
  getMyData() {
    this.authService.currentUser.subscribe((user: User) => {
      if (!_.isEmpty(user)) {
        this.userDetails = user;
        // this.router.navigateByUrl('/student/dashboard/user/' + this.userDetails.username);
      }
    });
  }
  comingsoon() {
    Swal.fire({
      title: 'This feature will be coming soon!!!!',
      showClass: {
        popup: 'animated fadeInDown faster'
      },
      hideClass: {
        popup: 'animated fadeOutUp faster'
      }
    });
  }
  viewNotification(){
    this.notificationService.updateNotificationViewStatus();
    this.notificationService._notificationList.subscribe(notice=>{
      console.log(notice);
      if(notice.length>0){
        this.notificationList=notice;
      }
    })
    console.log(this.notificationList);
  }
  
}
