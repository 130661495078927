import { AuthService } from 'src/app/_cluster/services/auth.service';
import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { User } from './_cluster/_modal/user';
import * as _ from 'underscore';
// import swal from 'sweetalert2';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  photo = '';
  bordershadow = '';
  constructor(private mdbSpinningPreloader: MDBSpinningPreloader, private authService: AuthService) { }
  ngOnInit() {
    // coverimgdiv
    this.mdbSpinningPreloader.stop();
    this.authService.currentUserDashboardSubject.subscribe((user: User) => {
     // tslint:disable-next-line: no-unused-expression
     if (!_.isEmpty(user)) {
       this.photo = user.theme_image === '' ? '' : user.theme_image;
       if (this.photo === '') {
           this.bordershadow = '';
       } else {
           this.bordershadow = '5px solid #000';
       }
     }

    });
    // swal.fire(
    //   {
    //     icon: 'success',
    //     text: 'Registration Done',
    //     showConfirmButton: true,
    //     width:'370px'
    //   });

  }

}
