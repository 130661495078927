import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class Api {
  url: string = `${environment.apiurl}`;
  token: string = null;
  constructor(public http: HttpClient) {
  }
  setToken(token) {
    this.token = token;
  }
  setHeaders() {
    if (!!this.token) {
      try {
        let headersConfig = {
          Authorization: "Bearer " + JSON.parse(this.token)
        };
        return headersConfig;
      } catch (e) {
        let headersConfig = {
          Authorization: "Bearer " + this.token
        };
        return headersConfig;
      }
    } else {
      let token = localStorage.getItem(environment.accessToken);
      if (token) {
        this.token = token;
        let headersConfig = {
          Authorization: "Bearer " + JSON.parse(this.token)
        };
        return headersConfig;

      } else {
      }
    }
  }

  get(endpoint: string, params?: any, reqOpts?: any, showLoader: boolean = true) {

    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: new HttpHeaders()
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    if (reqOpts) {
      reqOpts.headers = { ...this.setHeaders(), ...reqOpts };
    }

    reqOpts.headers = new HttpHeaders(this.setHeaders());
    if (showLoader) {

      // this.spinner.show();
      let seq = this.http.get(this.url + '/' + endpoint, reqOpts);

      return seq;
    } else {

      let seq = this.http.get(this.url + '/' + endpoint, reqOpts);

      return seq;

    }

  }

  post(endpoint: string, body: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: this.setHeaders()
      };
    }

    return this.http.post(this.url + '/' + endpoint, body, { params: reqOpts.params, headers: new HttpHeaders(this.setHeaders()) });
  }
  postFormData(endpoint: string, body: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
      };
    }

    return this.http.post(this.url + '/' + endpoint, body, { params: reqOpts.params, headers: reqOpts.headers });
  }
}
